.newsletter{
	background-color: $secondary;
	background-image: url('../images/flowersoverlay12.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	color: #fff;
	position: relative;
	padding: $spacing*1.5 0px $spacing 0px;
	&-nav{
		position: absolute;
    top: -26px;
    left: 50%;
    transform: translate(-50%, 0%);
		ul{
			padding: 0px;
			margin: 0px;
			list-style-type: none;
			width: 100vw;
			li{
				margin: 0px 5px;
				display: inline-block;
				a{
					background-color: $primary;
					font-size: 18px;
					padding: 15px 40px;
					display: block;
					transition: all 0.3s;
					color: #fff;
					-webkit-backface-visibility: hidden;
				  -webkit-transform: translateZ(0) scale(1.0, 1.0);
				  transform: translateZ(0);
					&:hover, &:focus{
						filter: brightness(85%);
					}
					@media (max-width: $mobile) {
						padding: 10px 20px;
						font-size: 14px;
					}
				}
			}
		}
	}	
	&-title{
		font-size: 24px;
		font-weight: 600;		
	}
	.email-form{
		input{
			border: 1px solid #707070;
			color: $text;
			padding: 10px;
			width: 600px;
			font-size: 18px;
			height: 52px;
			@media (max-width: $mobile) {
				width: calc(100% - 85.91px);
				float: left;
			}
			&::placeholder {
		    color: #767676;
		    opacity: 1; /* Firefox */
			}
		}
		.btn{
			width: auto;
			border: none;
			background-color: $primary;
			color: #fff;
			padding: 15px 40px;
			cursor: pointer;
			-webkit-appearance: none;
			@media (max-width: $mobile) {
				font-size: 16px;
				padding: 11px 18px;
				
				margin-top: 0px;
			}
		}
	}
}

.footer_icons {
	background-color: #fff;
	padding: $spacing 0px;
	text-align: center;
	ul {
		display: flex;
		margin: 0;
		padding: 0;
		list-style-type: none;
		flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @media (max-width: $mobile) {
    	justify-content: left;
    }
		li {
			padding: 10px;
			display: inline-block;
			text-align: center;
			@media (max-width: $mobile) {
				flex-basis: 50%;
				padding: 10px;
			}
			img {
				height: 60px;
				@media (max-width: $mobile) {
					height: 45px;
				}
			}
		}
	}
}

.footer{
	background-color: $third;
	padding: $spacing 0px;
	text-align: center;
	&-left{
		text-align: left;
		@media (max-width: $mobile) {
			margin-top: 20px;
			text-align: center;
		}
	}
	&-address{
		color: $secondary;
		font-size: 14px;
		line-height: 22px;
	}
	a{
		color: $secondary;
		text-decoration: none;
		transition: all 0.3s;
		position: relative;
		font-size: 14px;
		line-height: 22px;
		// &:after{
		// 	content: '';
		// 	position: absolute;
		// 	left: 0;
		// 	right: 0; 
		// 	bottom: -1px;
		// 	height: 1px;
		// 	background-color: $secondary;
		// 	width: 100%;
		// }
		text-decoration: underline;
		&:hover, &:focus{
			opacity: 0.8;
		}
	}

	&-logos {
		float: right;
		a{
			&:after{
				display: none !important;
			}
		}
		img {
			margin: 0 10px;
			height: 80px;
		}
		@media (max-width: $mobile) {
			float: none;
		}
	}

	&-links {
		padding: 0 0 $spacing/2;
		a {
			display: inline-block;
			margin: 0 5px;
			&:first-child {
				margin-left: 0px;
			}
			&:last-child {
				margin-right: 0px;
			}
		}
	}
	.county-links {
		display: flex;
    flex-wrap: wrap;
    padding: 0 0 20px 0;
		li {
			flex: 1;
			flex-basis: 33%; 
		}
	}
}

