html, body {
  padding: 0px;
  margin: 0px;
  height: 100%;
}
body{
  zoom: 90%;
  padding: 128px 0px 0px 0px;
  overflow-x: hidden;
  @media (max-width: $mobile) {
    padding: 83px 0px 0px 0px;
    zoom: 1;
  }
}
 
@media all and (-ms-high-contrast:none){
   body { zoom: 100%; } /* IE10 */
   *::-ms-backdrop, body { zoom: 100%; } /* IE11 */
}


* {
  box-sizing: border-box;
  z-index: 0
}

.reverse{
  .col-7{
    order: 2;
  }
}

.wrapper{
  min-height: 100%;
  margin-bottom: -375px; // Footer Height
  &:after {
    content: "";
    display: block;
  }
}

// .footer, .wrapper:after {
//   height: 100px; 
// }

.wrapper:after {
  height: 375px; 
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: $spacing;
  padding-right: $spacing;  
  @media (max-width: $mobile) {
    padding-left: 20px;
    padding-right: 20px;  
  }
  &:before, &:after{
    content: " ";
    display: table; 
  }
  &:after{
    clear: both;
  }
  @media (min-width: $mobile) {
    width: 100%;
  } 
  @media (min-width: $small-screen) {
    width: $small-screen; 
  }
  @media (min-width: $large-screen) {
    width: $large-screen; 
  }
}

.pull-right{
  float: right;
}
.pull-left{
  float: left;
}

//////////////////////////////  GRID
.row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -$col-spacing;
  margin-right: -$col-spacing;

}
.col {
  flex: 1;
  padding: $col-spacing;
} 

// Small Grid;

@for $i from 1 through 12 {
  .col-sm-#{$i} {
    width: calc(#{$i}/12 * 100%);
    padding: $col-spacing;
  }
}

// Medium Grid;

@media (min-width: $mobile) {

  @for $i from 1 through 12 {
    .col-md-#{$i} {
      width: calc(#{$i}/12 * 100%);
      padding: $col-spacing;
    }
  }

}

// Large Grid;

@media (min-width: $large-screen) {

  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      width: calc(#{$i}/12 * 100%);
      padding: $col-spacing;
    }
  }

}

/////////////////////////////// Shorthand Grid
@for $i from 1 through 12 {
  .col-#{$i} {
    width: 100%;
    padding: $col-spacing;
  }
}
.col-auto{
  @extend .col-1;
  flex: none;
}

// Mobile Grid shorthand

@media (min-width: $mobile) {

  @for $i from 1 through 12 {
    .col-#{$i} {
      width: calc(#{$i}/12 * 99.9%);
      padding: $col-spacing;
    }
  }
  .col-auto {
    flex: 1;
    padding: $col-spacing;
  }
}

/////////////////////////// Column Order 
@for $i from 1 through 12 {
  .col-order-#{$i} {
    order: $i;
  }
}

/////////////////////////// Row Justify
.row-right {
  justify-content: flex-end;
}
.row-center {
  justify-content: center;
}

/////////////////////////// Row Align
.row-align-bottom {
  align-items: flex-end;
}
.row-align-center {
  align-items: center;
}

/////////////////////////// Column Align
.col-align-bottom {
  align-self: flex-end;
}
.col-align-center {
  align-self: center;
}