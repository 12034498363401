.faq_holder {
	width: 100%;
	li:first-of-type {
		padding: 0px !important;
	}
	li {
		background-color: #fff;
		.faq_question {
			font-size: 22px;
			color: #000;
			cursor: pointer;
			transition: all 0.4s ease;
			border-bottom: 1px dotted #666;
    	padding: 10px 10px;
    	position: relative;
    	.faq_chevron {
    		height: 24px;
    		width: 12px;
    		background-image: url('../images/chevron.svg');
    		transform: rotate(-90deg);
    		transition: all 0.4s ease;
    		position: absolute;
		    right: 10px;
		    top: 12px;
		    background-size: cover;
    	}
		}
		.faq_answer {
			display: none;
			padding: 0px 10px;
			p {
				font-size: 18px;
			}
		}
	}
	.open .faq_chevron {
		transform: rotate(90deg) !important;
	}
}