.download{
	background-color: $third;
	.brochure-form{
		max-width: 600px;
		margin: auto;
		h2, h4, .privacy p{
			color: $secondary;
			text-align: center;
		}
		.privacy a{
			text-decoration: underline;
			font-weight: 700;
		}
		h2, h4, p{
			color: $secondary;
		}
		input[type="checkbox"]{
			zoom: 1.5;
			-webkit-appearance: checkbox;
		}
		.form-group{
			margin: 0px 0px 20px 0px;
			input{
				border: 0px;
				margin: 0px 0px 5px 0px;
			}
		}
		.btn{
			width: 100%;
			border: none;
			cursor: pointer;
			text-align: center;
		}
		.carehome{
			display: none;
		}
	}
}

.thankyou{
	background-color: $third;
	margin-bottom: 75px;
	.content{
		padding: 60px 0px;
	}
	.thankyou-form{
		max-width: 600px;
		margin: auto;
		text-align: center;
		h2,h3,p{
			color: $secondary;
		}
	}
}