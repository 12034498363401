.locations{
	.header{
		&-title{
			background-color: $third;
			background-image: url('../images/flowersoverlay.svg');
		}
	}
}
.grid{
	padding: $spacing 0px $spacing*2 0px;
	h2{
		font-weight: 400;
		color: $secondary;
		margin-top: 0px;
		width: 100%;
		text-align: center;
		span{
			color: $primary;
			font-family: Quincy;
			font-weight: 600;
			font-size: 2em;
			margin-right: 5px;
		}
	}
	.col-4{
		position: relative;
		@media (max-width: $small-screen) {
			flex-basis: 50%;
		}
		@media (max-width: $mobile) {
			flex-basis: 100%;
		}
	}
}

.carehome-holder{
	background-color: #fff;
	cursor: pointer;
	position: relative;
	&-img{
		transition: all 0.3s;
		img{
			width: 100%;
			height: 220px;
			object-fit: cover;
		}
		margin: 0px 0px $spacing/2 0px;
	}
	&-content{
		&-title{
			color: $primary;
			font-weight: 600;
			font-size: 24px;
		}
		&-about{
			color: $text;
		}
		&-details{
			div{
				color: $text;
				width: 100%;
				font-weight: 600;
			}
			&-phone{
				color: $primary !important;
				font-weight: 600;
			}
		}
	}
	.btn{
		cursor: pointer;
		text-align: center;
	}

	&:hover{
		.btn{
			// width: 100%;
			filter: brightness(85%);
		}
		.carehome-holder-img{
			filter: brightness(85%);
		}
	}
}
.new-summer {
	&:after{
		content: '';
		position: absolute;
		right: -20px;
		top: -20px;
		height: 80px;
		width: 80px;
		background-image: url('../images/summer.svg');
		background-repeat: no-repeat;
	}
}
.header-slider{
	visibility: hidden;
}
.slick-initialized{
	visibility: visible;
}
