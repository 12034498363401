.carehome{
	.header{
		&-title{
			background-color: $primary;
			background-image: url('../images/flowersoverlay.svg');
		}
	}
	.col-5{
	}
	.row:first-of-type{
		padding: 0px 0px $spacing 0px;
	}
	.sketch-mug{
		position: relative;
		&:after{
			content: '';
			position: absolute;
			bottom: -128px;
    	left: -123px;
			height: 220px;
			width: 180px;
			background-image: url('../images/mug.svg');
			background-repeat: no-repeat;
			@media (max-width: $mobile) {
				left: -10px;
				top: -10px;
			}
		}
		@media (max-width: $mobile) {
			&:after{
				display: none;
			}
		}
	}	
	.reverse{
		.col-7, .col-5{
			padding: 20px;
			&:after{
				display: none;
			}
		}
	}
	.content{
		padding: $spacing 0px;
	}
	.map{
		height: 500px;
		width: 100%;
		#map{
			height: 100%;
			width: 100%;
		}
	}

	.widgets{
		text-align: center;
		.img-holder{
			padding: 10px;
			border: 1px solid #707070;
		}
		img{
			width: 100%;
		}
	}

}

.return-home{
	color: $primary;
}


.cavendish-reverse{
	@media (min-width: $mobile) {
		.col-7{
			order: 2;
			padding-left: 60px;
			padding-right: 0px;
		}
		.col-5{
			padding-right: 60px;
			padding-left: 0px;
		}
	}
}

.single-carehome{
	.content{
		padding: 0px;
	}
}

.carehome-intro, .carehome-features, .message-manager, .carehome-message, .carehome-ratings, .download{
	padding: $spacing 0px;
	.row{
		padding: 0px !important;
	}
}

.carehome-features{
	background-color: #174253;
  background-image: url('../images/flowersoverlay12.svg');
  .content-main-title{
  	color: #fff;
  	margin-bottom: 15px;
  }
  color: #fff;
  ul{
  	margin-left: 30px;
  	li{
  		position: relative;
  		&:first-of-type{
  			padding: 5px 0px;
  		}
  		&:after{
  			content: '';
  			position: absolute;
  			left: -30px;
  			top: 5px;
  			height: 20px;
  			width: 20px;
  			background-image: url('../images/check-circle.svg');
  		}
  	}
  }
}


.event-btn {
  background-color: #fff;
  font-size: 18px;
  padding: 15px 40px;
  display: block;
  transition: all .3s;
  color: #3c3c3c;
  transform: translateZ(0);
	display: inline-block;
	margin: 20px;
  &:hover {
  	filter: brightness(85%);
  }
}

.message-manager-img{
	height: 100%;
	width: 100%;
	position: relative;
	img{
		position: absolute;
		top: 50%;
		width: 100%;
		transform: translate(0%, -50%);
		@media (max-width: $mobile) {
			position: relative;
			top: 0;
			width: 100%;
			transform: none;
		}
	}
}

.testimonial-gallery-prev{
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(0%, -50%);
	img{
		height: 20px;
	}
}
.testimonial-gallery-next{
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(0%, -50%);
	img{
		height: 20px;
		transform: rotate(180deg);
	}
}


.carehome-gallery-holder{
	padding: 0px 20px;
	position: relative;
	.carehome-gallery{
		visibility: hidden;
		.slick-list{
			img{
				width: 100%;
				height: 400px;
				object-fit: cover;
			}
		}
	}
	.slick-initialized{
		visibility: visible !important;
	}
	.carehome-gallery-prev{
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(0%, -50%);
		img{
			height: 20px;
		}
	}
	.carehome-gallery-next{
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(0%, -50%);
		img{
			height: 20px;
			transform: rotate(180deg);
		}
	}
}

.align-center{
	text-align: center;
}

.visit-message{
	div[data-class="wpcf7cf_group"] {
		margin: 0px 20px !important;
	}
	.brochure-form {
		max-width: 100% !important;
	}
	label {
		font-size: 18px;
		line-height: 28px;
		a {
			font-weight: 700;
    	text-decoration: underline;
    	color: inherit;
		}
	}
	.wpcf7-list-item {
		margin: 0px;
		.wpcf7-list-item-label {
			font-size: 18px;
		}
		input {
			margin-left: 10px;
			zoom: 1.5;
    	-webkit-appearance: checkbox;
		}
	}
	p a{
		color: inherit;
	}
	background-color: #F5F5F5;
	display: none;
	position: relative;
	padding: 40px 0;
	input[type=text], input[type=email], input[type=tel], textarea, select{
		width: 100%;
		font-size: 18px;
		color: #000;
		padding: 15px;
		resize: none;
		border: 1px solid rgba(#000, 0.4);
		margin-top: 0px;
		background-color: #fff;
		border-radius: 0px;
		appearance: none;
		&::placeholder{
			color: rgba(#000, 0.4);
		}
	}
	input[type=submit]{
		margin: 0px;
	}
	.btn{
		width: auto;
	}
	#carehome-field{
		display: none;
		height: 1px;
	}	
	.container{
		position: relative;
	}
	.visit-message-close{
		cursor: pointer;
		position: absolute;
		top: 20px;
		right: 65px;
		color: #818181;
		&:after{
			content: '';
			background-image: url('../images/close.svg');
			height: 20px;
			width: 20px;
			position: absolute;
			right: -30px;
			top: 1px;
			background-repeat: no-repeat;
		}
	}
}

.carehome-message{
	background-color: #F5F5F5;
	display: none;
	position: relative;
	div[data-class="wpcf7cf_group"] {
		margin: 0px 20px !important;
	}
	.brochure-form {
		max-width: 100% !important;
	}
	label {
		font-size: 18px;
		line-height: 28px;
		a {
			font-weight: 700;
    	text-decoration: underline;
    	color: inherit;
		}
	}
	.wpcf7-list-item {
		margin: 0px;
		.wpcf7-list-item-label {
			font-size: 18px;
		}
		input {
			margin-left: 10px;
			zoom: 1.5;
			transform: scale(1.2);
    	-webkit-appearance: checkbox;
		}
	}
	p a{
		font-weight: 700;
		text-decoration: underline;
	}
	input[type=text], input[type=email], input[type=tel], textarea{
		width: 100%;
		font-size: 18px;
		color: #000;
		padding: 15px;
		resize: none;
		border: 1px solid rgba(#000, 0.4);
		&::placeholder{
			color: rgba(#000, 0.4);
		}
	}
	input[type=submit]{
		margin: 0px;
	}
	.btn{
		width: auto;
	}
	#carehome-field{
		display: none;
		height: 1px;
	}	
	.container{
		position: relative;
	}
	.carehome-message-close{
		cursor: pointer;
		position: absolute;
		top: 20px;
		right: 65px;
		color: #818181;
		&:after{
			content: '';
			background-image: url('../images/close.svg');
			height: 20px;
			width: 20px;
			position: absolute;
			right: -30px;
			top: 1px;
			background-repeat: no-repeat;
		}
	}
}
.carehome{
	.header{
		.header-title{
			padding: 60px 0 40px 0;
			// @media (min-width: 768px){
			// 	padding: 110px 0 40px 0;
			// }
			ul{
				width: 100%;
			}
		}
	}
}
.carehome-ratings p{
	margin: 0px;
}
.cqc-widget{
	height: 100% !important;
}





#download{
	.download{
		position: relative;
		display: none;
		.container{
			position: relative;
		}
		.download-message-close{
			cursor: pointer;
			position: absolute;
			top: 20px;
			right: 65px;
			color: #818181;
			&:after{
				content: '';
				background-image: url('../images/close.svg');
				height: 20px;
				width: 20px;
				position: absolute;
				right: -30px;
				top: 1px;
				background-repeat: no-repeat;
			}
		}
	}
}

#download .download{
	display: none;
	position: relative;
	div[data-class="wpcf7cf_group"] {
		margin: 0px 20px !important;
	}
	.brochure-form {
		max-width: 100% !important;
	}
	label {
		font-size: 18px;
		line-height: 28px;
		a {
			font-weight: 700;
    	text-decoration: underline;
    	color: inherit;
		}
	}
	.wpcf7-list-item {
		margin: 0px;
		.wpcf7-list-item-label {
			font-size: 18px;
		}
		input {
			transform: scale(1.2);
			margin-left: 10px;
		}
	}
	p a{
		font-weight: 700;
		text-decoration: underline;
	}
	input[type=text], input[type=email], input[type=tel], textarea{
		width: 100%;
		font-size: 18px;
		color: #000;
		padding: 15px;
		resize: none;
		border: 1px solid rgba(#000, 0.4);
		&::placeholder{
			color: rgba(#000, 0.4);
		}
	}
	input[type=submit]{
		margin: 0px;
	}
	.btn{
		width: auto;
	}
	#carehome-field{
		display: none;
		height: 1px;
	}	
	.container{
		position: relative;
	}
	.carehome-message-close{
		cursor: pointer;
		position: absolute;
		top: 20px;
		right: 65px;
		color: #818181;
		&:after{
			content: '';
			background-image: url('../images/close.svg');
			height: 20px;
			width: 20px;
			position: absolute;
			right: -30px;
			top: 1px;
			background-repeat: no-repeat;
		}
	}
}

