.activities{
	padding: $spacing 0px;
	background-color: $third;
	text-align: center;
	color: #174253;
	background-image: url('../images/flowersoverlay.svg');
	.tile{
		.tile-holder{
			.tile-holder-title{
				background-color: #fff;
				color: #174253;
			}
		}
	}
}

.primary-content{
	padding: $spacing 0px;
}

.news{
	.header-title{
		padding: $spacing 0px;
	}
	.tile{
		padding: $spacing 0px $spacing*1.5 0px;
		.month{
			.news-holder{
				height: 100%;
				width: 100%;
				background-color: $primary;
				color: #fff;
				position: relative;
				.month-title{
					font-size: 22px;
					font-weight: 200;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
				@media (max-width: $mobile) {
					height: 180px;
				}
			}
		}
		.news-holder{
			transition: all 0.3s;
			&-img{
				transition: all 0.3s;
				img{
					width: 100%;
					height: 180px;
					object-fit: cover;
					position: relative;
					float: left;
				}
			}
			.news-content{
				transition: all 0.3s;
				background-color: #EBEEEF;
				position: relative;
				.read-more{
					color: $primary !important;
					font-size: 16px;
					float: right;
					display: inline-block;
					margin: 12px 0px 0px 0px;
				}
				padding: 12px;
				&-title{
					font-size: 18px;
					color: $text;
					font-weight: 600;
					min-height: 43px;
				}
				&-date{
					font-weight: 200;
					font-size: 18px;
					color: $text;
					float: left;
					display: inline-block;
					margin: 12px 0px 0px 0px;
				}
			}
			&:hover, &:focus{
				.news-holder-img{
					filter: brightness(85%);
				}
				.news-content{
					padding: 18px 12px 18px 12px;
					margin-top: -12px;
				}
			}
		}
	}
}

.post{
	.header-title{
		background-color: $primary;
		background-image: url('../images/flowersoverlay.svg');
	}
	.header-title-address{
		margin-bottom: 0px;
	}
}
.post{
	h2{
		margin: 40px 0px 20px 0px;
		color: $primary;
		font-family: Quincy;
		font-weight: 700;
	}
	.content:last-of-type{
		padding-top: 0px;
	}
	.share{
		padding: $spacing/2 0px 0px 0px;
		height: 100px;
		div{
			float: left;
			padding-top: 10px;
			margin-right: 10px;
		}
		ul{
			float: left;
			margin: 0px;
			padding: 0px;
			li{
				display: inline-block;
				a{
					display: block;
				}
				img{
					height: 30px;
					display: block;
				}
			}
		}
	}
}

.event-header{
	.header-title{
		background-color: $primary;
		background-image: url('../images/flowersoverlay.svg');
	}
}
.events-tile{
	.news-holder{
		&:hover, &:focus{
			.news-holder-img{
				filter: brightness(85%);
			}
			.news-content{
				padding: 26px 20px 26px 20px !important;
				margin-top: -12px;
			}
		}
	}
	.news-content{
		padding: $spacing/2 !important;
		font-size: 22px;
		color: $secondary;
		.event-date{
			float: left;
			font-weight: 200;
			display: inline-block;
			span{
				font-weight: 700;
				font-size: 32px;
			}
		}
		.event-title{
			float: right;
			font-weight: 200;
			display: inline-block;
			line-height: 44px;
		}
	}
}

.gardening{
	.header-title-text{
		color: #174253;
	}
}

.our-story{
	.content-main-title{
		text-align: left !important;
	}
}

.sketch-book{
	position: relative;
	&:after{
    content: '';
    position: absolute;
    height: 200px;
    width: 253px;
    background-image: url('../images/book.svg');
    background-repeat: no-repeat;
    top: -75px;
    right: -100px;
		@media (max-width: $mobile) {
			left: -10px;
			top: -10px;
		}
	}
	@media (max-width: $mobile) {
		&:after{
			display: none;
		}
	}
}	
.activites-events{
	.tile-holder-img{
		height: 300px;
	}
	.tile-holder-title{
		padding: 20px 0px;
		font-size: 18px;
	}
	.tile-holder:hover .tile-holder-title{
		padding: 26px 0px;
	}
}

.header-whats-happening{
	color: #fff;
	font-weight: 200;
	font-size: 22px;
	div{
		display: inline-block;
		margin-left: 20px;
		width: 300px;
		text-align: left;
	}
}


.page-id-184{
	.content{
		padding-bottom: 0px;
	}
}


