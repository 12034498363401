.header-slider{
	height: 500px;
	@media (max-width: $mobile) {
		height: 300px;
	}
	div{
		display: block;
	}
	.header-slider-image{
		height: 500px;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		@media (max-width: $mobile) {
			height: 300px;
			background-position: center;
		}
	}
}



.carousel{
	.slick-prev, .slick-next{
		display: none !important;
	}
	.dots{
		text-align: center;
		margin-top: 10px;
		li{
			display: inline-block;
			height: 10px;
			width: 10px;
			border-radius: 10px;
			background-color: #CCCCCC;
			margin: 0px 10px;
			cursor: pointer;
		}
		.slick-active{
			background-color: #999999;
		}
	}
}

.values-holder{
	@media (max-width: $mobile) {
		padding: 0px 20px;
	}
	.col{
		@media (max-width: $mobile) {
			flex: none;
			width: 50%;
			flex-basis: 50%;
		}
	}
}
.values{
	// margin: $spacing/2;
	padding: $spacing/2;
	border: 1px solid #000;
	height: 100%;
	&-title{
		color: $primary;
		font-weight: 600;
		font-size: 22px;
	}
}

.events{
	margin: 10px 5px 0px 5px;
	cursor: pointer;
	@media (max-width: $mobile) {
		padding: 15px 20px;
	}
	&-img{
		img{
			height: 400px;
			width: 100%;
			object-fit: cover;
			@media (max-width: $mobile) {
				height: 250px;
			}
		}
	}
	&-content{
		background-color: $primary;
		color: #fff;
		padding: $spacing/2;
		font-size: 22px;
		&-date{
			float: left;
			font-weight: 200;
			display: inline-block;
			span{
				font-weight: 700;
				font-size: 32px;
			}
		}
		&-time{
			float: right;
			font-weight: 200;
			display: inline-block;
			line-height: 44px;
		}
	}
	&-title{
		padding: $spacing/2 0px;
		font-size: 22px;
		font-weight: 200;
		color: #fff;
	}
}
.events-slider{
	background-color: $secondary;
	padding-bottom: 5px;
	background-image: url('../images/flowersoverlay12.svg');
	.slick-slide{
		outline: none;
		@media (min-width: $mobile) {
			width: 600px !important;
		}
	}
}



.who-slider{
	.slick-slide img {
    display: block;
    width: 100%;
    height: 317px;
    object-fit: cover;
  }
	.slick-prev, .slick-next{
		display: none !important;
	}
	.dots{
		text-align: center;
		margin-top: 10px;
		li{
			display: inline-block;
			height: 10px;
			width: 10px;
			border-radius: 10px;
			background-color: #CCCCCC;
			margin: 0px 10px;
			cursor: pointer;
		}
		.slick-active{
			background-color: #999999;
		}
	}
}



