html{
	font-family: $primary-font;
}
h1,h2,h3,h4,h5,h6{
	font-family: $primary-font;
}
h1,.h1 { font-size: 32px; }
h2,.h2 { font-size: 28px; }
h3,.h3 { font-size: 24px; }
h4,.h4 { font-size: 20px; }
h5,.h5 { font-size: 18px; }
h6,.h6 { font-size: 16px; }

a, button{
	font-family: $secondary-font;
	text-decoration: none;
}
input{
	font-family: $secondary-font;
}

p{
	font-size: 18px;
	line-height: 26px;
}

.btn{
	display: inline-block;
	background-color: $primary;
	padding: 12px 60px;
	font-size: 16px;
	font-weight: 400;
	color: #fff !important;
	margin: 16px 0px 0px 0px;
	transition: all 0.3s;
	&:hover, &:focus{
		filter: brightness(85%);
	}
}


p a{
	color: inherit;
}

ul{
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	li{
		padding: 5px 0px;
		&:first-of-type{
			padding: 0px 0px 5px 0px;
		}
	}
}

input[type=submit], input{
	-webkit-appearance: none;
}
