.carehome-contact {
	display: none;
}

select{
	border: 1px solid #707070;
	color: $text;
	padding: 10px;
	width: 600px;
	font-size: 18px;
	height: 52px;
	margin: 20px 0 0;
	@media (max-width: $mobile) {
		width: 100%;
	}
	&::placeholder {
    color: #767676;
    opacity: 1; /* Firefox */
	}
}

.enquiry select{
	display: block;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  padding: 12px;
  border: 1px solid #707070;
  margin: 0 0 40px 0;
  color: #757575 !important;
  border-radius: 0;
  -webkit-appearance: none;
  background-color: transparent;
  height: 44.86px;
}

@media (max-width: $mobile) {
	.tribe-bar-views-option-month{
		display: none !important;
	}
}


.contact-form{
	.form-group{
		input, select, textarea{
			margin: 40px 0 10px 0;
		}
		label {
			position: relative;
			a {
				color: inherit;
				font-weight: 700;
    		text-decoration: underline;
			}
		}
		input[type=checkbox] {
			position: absolute;
			top: 50%;
			transform: translate(0%, -50%) scale(1.2);
			zoom: 1.2;
			width: 13px !important ;
    	appearance: checkbox !important;
    	margin: 2px 0 0 0;
		}
		&:first-of-type{
			input{
				margin-top: 0px;
			}
		}
	}
}

.popup-signup{
	p{
		display: none;
	}
	#event, #date, #time, #location{
		display: none;
	}
	.wpcf7 {
    margin: 0;
    padding: 0;
    width: 800px;
	}
	.form-group{
		width: 250px;
    margin: 0 2px;
    float: left;
    input{
   		margin: 0 0 10px 0;
    }
	}
	.wpcf7-response-output{
		margin: 20px 145px;
		float: left;
	}
}


.apply{
	.form-group{
		padding-bottom: 40px;
		&:last-of-type{
			padding-bottom: 0px;
		}
		input, textarea{
			margin-bottom: 10px;
		}
	}
	.btn{
		border: none !important;
	}
	#job-place{
		display: none;
	}
}

.map{
	height: 400px;
	div{
		height: 100%;
	}
}

.carehome-contact{
	.content-img img {
    width: 100%;
    height: 350px;
    object-fit: cover;
	}
}


iframe {
	zoom: 1.11;
	@media (max-width: $mobile) {
		zoom: 1;
	}
}

