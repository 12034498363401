.recruitment{
	.header{
		&-title{
			background-color: $primary;
			background-image: url('../images/flowersoverlay.svg');
		}
		.header-slider{
			height: 500px;
			div{
				height: 100%;
				div{
					width: 100%;
					background-repeat: no-repeat;
					background-size: cover;
				}
			}
		}
		&-title-nav {
			select {
				font-family: Lato,sans-serif;
				width: 150px;
				background-color: #fff;
				font-size: 18px;
				padding: 15px;
				display: block;
				color: $text;
				border: 0;
			}
			input.btn {
				background-color: $secondary;
				font-size: 18px;
				color: #fff;
				padding: 15px;
				width: 100px;
				margin: 0;
				border: 0;
			}

			@media (max-width: 480px) {
				select, input.btn {
					width: 100px;
					padding: 10px 5px;
					font-size: 14px;
				}
			}			
		}
	}

	.content {
		text-align: center;

		.vacancy{
			text-align: left;
			margin: $spacing/2;
			padding: $spacing/2;
			border: 1px solid #000;
			&-title{
				color: $primary;
				font-weight: 600;
				font-size: 22px;
			}
			&-type {
				font-weight: 700;
			}
			&-rate {
				font-weight: 700;
				color: $primary;
			}			
			a {
				color: #3C3C3C;
				outline: none;
			}
		}		

		.vacancies {
			margin-bottom: $spacing;
		}
	}

	&-results {
		.header{
			&-title {
				background-color: $secondary;
				background-image: url('../images/flowersoverlay.svg');
			}
		}

		.filter-form {
			padding: 0 $spacing/2;

			div, select, input {
				width: 18%;
				display: inline-block;
				margin: 5px;
			}

			select {
				font-family: Lato,sans-serif;
				background-color: #fff;
				font-size: 18px;
				padding: 15px;
				color: $text;
			}
			input.btn {
				background-color: $secondary;
				font-size: 18px;
				color: #fff;
				padding: 15px;
				margin: 5px;
				border: 0;
			}			
		}

		.listing {
			.vacancy {
				position: relative;

				.btn {
					position: absolute;
					bottom: $spacing/2;
					right: $spacing/2;
				}
			}
		}

		@media (max-width: 768px) {

			.filter-form {
				div {
					width: 100%;
					float: none;
				}

				select {
					width: 30%;
				}

				input.btn {
					width: 200px;
					display: block;
					float: none;
					margin: 5px auto;
				}
			}		
		}

		@media (max-width: 480px) {

			.listing {
				.vacancy {
					position: relative;

					.btn {
						position: relative;
						bottom: 0;
						right: 0;
					}
				}
			}			
		}		
	}

	&-vacancy {
		.header{
			&-title {
				background-color: $third;
				background-image: url('../images/flowersoverlay.svg');
			}
			&-title-text, &-title-address{
				color: $secondary;
			}			
		}

		.content {
			text-align: left;
			
			h3{
				margin: 40px 0px 20px 0px;
				color: $primary;
				font-family: Quincy;
				font-weight: 700;
			}		

			hr {
				border:0;
				border-top: 1px #707070 solid;
				margin: $spacing 0;
			}

			.apply-form {
				margin-bottom: $spacing;

				.col-6, .col-12 {
					padding-top: 0;
					padding-bottom: 0;
				}
				input, textarea {
					margin: 0 0 20px 0;

					&::placeholder {
					  color: #757575; 
					  opacity: 1;
					}
				}
				.form-group-center {
					text-align: center;
					input{
						width: 50%;
					}
				}
				button {
					width: 180px;
					margin: 10px;
					display: inline-block;
					padding: 12px 30px;
					border: 1px #707070 solid;
				}

				.light-btn {
					background-color: #EBEEEF;
					border: 1px #707070 solid;
					color: $secondary!important;
				}
			}	
		}		
	}
}

.recruit-content{
	max-width: 1000px;
	margin: auto;
}

.nav-small{
	top: 360px !important;
	font-size: 14px;
}

.recruitment .header-title-nav{
	ul{
		li{
			margin: 0px;
			margin-top: -5px;
			&:first-of-type{
				margin-right: 18px;
			}
			&:nth-child(2){
				margin-right: 15px;
			}
		}
	}
	select, input{
		width: 200px !important;
		margin: 0px;
	}
}

.search-terms{
	padding: 10px;
}


