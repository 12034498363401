.page-id-601, .page-id-344, .page-id-358, .page-id-368, .page-id-364, .page-id-360, .page-id-362, .page-id-366{
	.wrapper{
		margin-bottom: -450px !important;
	}
}


.lightblue{
	.header-title{
		background-color: $third;
		background-image: url('../images/flowersoverlay.svg');
		&-text{
			span{
				color: $secondary;
			}
		}
	}
}
.primary{
	.header-title-text {
		color: $secondary;
		span{
			color: $primary;
		}
	}
}
.orange{
	.header-title{
		background-color: $primary;
		background-image: url('../images/flowersoverlay.svg');
		&-text{
			span{
				color:  #fff;
			}
		}
	}
}


.subpage{
	.content{
		z-index: 1;
		h2{
			width: 100%;
			font-weight: 400;
			color: $secondary;
			margin-top: 0px;
			width: 100%;
			text-align: center;
			span{
				color: $primary;
				font-family: Quincy;
				font-weight: 600;
				font-size: 2em;
				margin-right: 5px;
			}
		}
		.content-main{
			.address{
				li{
					padding: 2px 0px;
				}
				margin-bottom: 16px;
			}
			.links a{
				color: inherit;
				transition: all .3s;
				transform: translateZ(0);
				&:hover{
					opacity: .8;
				}
			}
		}
	}
	.title{
		color: $primary;
		font-family: Quincy;
		font-weight: 700;
		position: relative;
		&:after{
			content: '';
			position: absolute;
			left: 0;
			bottom: -5px;
			width: 15px;
			height: 2px;
			background-color: $primary;
		}
	}
	.large-title{
		font-weight: 400;
		color: $secondary;
		font-size: 24px;
		span{
			color: $primary;
			font-family: Quincy;
			font-weight: 700;
			font-size: 2em;
		}
	}
}

.bottom-sketch{
	text-align: center;
	z-index: 0;
	.book{
		padding: 0px 0px $spacing*2 0px;
		max-width: 500px;
	}
	.gardening{
		padding: 0px 0px $spacing*2 0px;
		max-width: 200px;
	}
	.flowers{
		max-width: 80%;
		margin-bottom: -100px;
		margin-top: -40px;
	}
	@media (max-width: $mobile) {
		display: none;
	}
}
.bottom-sketch.dine{
	text-align: left;
	img{
		margin-bottom: -10px;
		max-width: 50%;
		margin-top: -300px;
		@media (max-width: 1350px) {
			opacity: 0;
		}
	}
}

.equal{
	.col-padding{
		padding-right: $spacing*3;
		@media (max-width: $mobile) {
			padding-right: 20px;
		}
	}
}

.contact .content, .who .content{
	padding: $spacing 0px $spacing*2 0px;
}

.content-sketch{
	margin-top: -280px;
	z-index: 0;
	@media (max-width: $mobile) {
		display: none;
	}
}

.right-sketch{
	position: absolute;
	top: -245px;
	right: 0;
	img{
		height: 480px;
	}
	@media (max-width: $mobile) {
		display: none;
	}
}
.page-id-88 .content .container{
	position: relative;
}
.content-main{
	img{
		width: 100%;
		height: 100%;
	}
}
.single-post{
	.content{
		padding-bottom: 0px;
	}
	.content-two{
		padding-top: 0px !important;
	}
	.testimonial{
		margin-top: 40px;
	}
}
.pagination-holder {
	width: 100%;
	height: 80px;
	position: relative;
}

.pagination {
	clear: both;
	padding: 40px 0;
	position: relative;
	font-size: 18px;
	line-height: 16px;
	display: inline-block;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0%);
}

.pagination span, .pagination a {
	display: block;
	float: left;
	margin: 0px 10px;
	padding: 14px 18px 13px 18px;
	text-decoration: none;
	width: auto;
	color: #3C3C3C;
	border: 1px solid #3C3C3C;
	background: #fff;
}

.pagination a:hover{
	color: #fff;
	background: #D35635;
	border: 1px solid #D35635;
}

.pagination .current{
	padding: 14px 18px 13px 18px;
	background: #D35635;
	color: #fff;
	border: 1px solid #D35635;
}

.title-faq{
	cursor: pointer;
}

.whoweare-btns{
	top: auto;
	bottom: -26px;
	ul li a{
		background-color: $secondary;
		color: #fff;
	}
}

.who-we-are{
	.content{
		padding: $spacing*2 0px;
		.two-columns{
			column-gap: 40px;
	    column-count: 2;
	    @media (max-width: $mobile) {
	    	column-gap: 0px;
	   	 	column-count: 1;
	    }
	    p:first-child{
	    	margin-top: 0px;
	    }
			img{
				width: 100%;
				height: auto;
			}
		}
	}
	.video-bar{
		padding: $spacing*2 0px;
		background-color: rgba(174,210,204,.35);
    background-image: url('../images/flowersoverlay.svg');
    .video-holder{
    	margin: 0 80px;
    	position: relative;
    	.video-overlay{
    		transition: all 0.3s;
    		position: absolute;
    		top: 0;
    		left: 0;
    		right: 0;
    		bottom: 0;
    		background-repeat: no-repeat;
    		background-size: cover;
    		z-index: 999;
    		cursor: pointer;
    		&:after{
    			content: '';
    			position: absolute;
    			top: 0;
	    		left: 0;
	    		right: 0;
	    		bottom: 0;
	    		background-color: rgba(#000, 0.4);
    		}
    		img{
    			position: absolute;
    			top: 50%;
    			left: 50%;
    			height: 100px;
    			transform: translate(-50%, -50%);
    			pointer-events: none;
    			z-index: 9999;
    		}
    	}
    	.video-overlay.hide{
    		opacity: 0;
    		pointer-events: none;
    	}
    	video{
	    	width: 100%;
	    	margin: 0px;
	    }
    	@media (max-width: $mobile) {
    		margin: 0px;
    	}
    }
	}
	.our-values{
		padding: $spacing*2 0px;
		.row{
			margin-left: -10px;
			margin-right: -10px;
			.col{
				padding: 10px;
				position: relative;
				&:nth-child(1){
  				&:after{
  					content: '';
  					position: absolute;
  					top: 25px;
  					right: 25px;
  					background-image: url('../images/heart.svg');
  					height: 30px;
  					width: 30px;
  					background-repeat: no-repeat;
  				}
  			}
  			&:nth-child(2){
  				&:after{
  					content: '';
  					position: absolute;
  					top: 25px;
  					right: 25px;
  					background-image: url('../images/creativity.svg');
  					height: 30px;
  					width: 30px;
  					background-repeat: no-repeat;
  				}
  			}
  			&:nth-child(3){
  				&:after{
  					content: '';
  					position: absolute;
  					top: 25px;
  					right: 25px;
  					background-image: url('../images/family.svg');
  					height: 30px;
  					width: 30px;
  					background-repeat: no-repeat;
  				}
  			}
  			&:nth-child(4){
  				&:after{
  					content: '';
  					position: absolute;
  					top: 25px;
  					right: 25px;
  					background-image: url('../images/happiness.svg');
  					height: 30px;
  					width: 30px;
  					background-repeat: no-repeat;
  				}
  			}
  			&:nth-child(5){
  				&:after{
  					content: '';
  					position: absolute;
  					top: 25px;
  					right: 25px;
  					background-image: url('../images/trust.svg');
  					height: 40px;
  					width: 40px;
  					background-repeat: no-repeat;
  				}
  			}
				.values{
					background-color: #d35635;
    			background-image: url('../images/flowersoverlay.svg');
    			border: none;
    			position: relative;
				}
				.values-title{
					color: #fff;
				}
				.values-content{
					p{
						font-size: 16px;
						margin-bottom: 0px;
						color: #fff;
					}
				}
			}
		}
	}
}

.tailored{
	.content{
		padding: 20px 0 40px 0;
	}
	.tailored-section{
		border-bottom: 3px solid #E8E8E8;
		padding: 20px 0px;
		&:first-of-type{
			border-top: 3px solid #E8E8E8;
		}
		&:last-of-type{
			border-bottom: none;
		}
		img{
			width: 100%;
		}
		.tailored-title{
			color: #D35635;
			font-family: Quincy;
			font-weight: 700;
			position: relative;
			font-size: 32px;
			&:after{
				content: '';
				position: absolute;
		    bottom: -1px;
		    left: 1px;
		    background-color: #d35635;
		    width: 20px;
		    height: 2px;
			}
		}
	}
	.tailored-list{
		margin: 0px;
		padding: 0px;
		list-style-type: none;
		text-align: center;
		margin-bottom: 20px;
		li{
			display: inline-block;
			padding: 0px 20px;
			a{
				color: inherit;
				font-weight: 18px;
				&:hover, &:focus{
					filter: brightness(85%);
				}
			}
		}
	}
}

.team_members {
	background-color: #F7F7F7;
	padding: 20px 0 0 0;
	.container {
		position: relative;
	}
	.three-slider-prev, .three-slider-next {
		position: absolute;
		top: 50%;
		transform: translate(0%, -50%);
		img {
			width: 20px;
		}
	}
	.three-slider-prev {
		left: -10px;
	}
	.three-slider-next {
		right: -10px;
		img {
			transform: rotate(180deg);
		}
	}
	.slider-holder {
		padding: 20px;
		img {
			width: 100%;
		}
		.member_button {
			background-color: #AED2CC;
			padding: 15px;
			text-align: center;
			color: #004357;
			font-weight: 600;
			cursor: pointer;
		  width: 100%;
		  border: none;
		  outline: none;
		  transition: 0.4s;
		  font-size: 16px;
		}
		.member_info {
		  padding: 0 18px;
		  background-color: white;
		  max-height: 0;
		  overflow: hidden;
		  transition: max-height 0.2s ease-out;
		  font-size: 16px;
		  p {
		  	font-size: 16px;
		  }
		  .member_info_close {
		  	font-size: 16px;
		  	color: #004357;
				font-weight: 600;
				cursor: pointer;
				text-decoration: underline;
			  border: none;
			  outline: none;
			  margin-bottom: 20px;
			  padding: 0px;
		  }
		}
	}
}



