.nav.nav-list {
	.nav-items {
		display: block;
		position: absolute;
		top: 50%;
		right: 40px;
		transform: translate(0%, -50%);
		margin-left: 200px;
		.dropdown {
			position: relative;
			ul {
				display: none;
				background-color: transparent;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 100%;
				left: 10px;
				min-width: 250px;
				text-align: left;
				&:after {
					content: '';
			    position: absolute;
			    top: 8px;
			    left: 30px;
			    width: 0;
			    height: 0;
			    border-style: solid;
			    border-width: 0 12px 12px 12px;
			    border-color: transparent transparent $secondary transparent;
				}
				&:hover {
					display: block;
				}
				li {
					padding: 0;
					background-color: $secondary;
					width: 100%;
					display: block;
					&:first-child {
						margin-top: 20px;
						padding-top: 10px;
					}
					&:last-child {
						padding-bottom: 10px;
					}
					a {
						color: #fff;
						width: 100%;
						display: block;
						padding: 10px 20px;
					}
				}
			}
			&:hover {
				ul {
					display: block;
				}
			}
		}
		ul {
			text-align: right;
			li {
				display: inline-block;
				@media (max-width: $mobile) {
					display: none;
					&:last-child {
						display: block;
					}
				}
				a {
					padding: 10px;
					font-size: 18px;
			    color: #3c3c3c;
			    transition: all 0.2s;
					&:hover, &:focus{
						color: $primary;
					}
			    @media (max-width: 1300px){
			    	font-size: 16px;
			    }
				}
				.nav-phone {
					position: relative;
					right: auto;
					top: auto;
					transform: none;
				}
			}
		}
	}
	.nav-menu {
		display: none;
		@media (max-width: $mobile) {
			display: block;
		}
	}
	.nav-logo {
		display: inline-block;
		@media (max-width: $mobile) {
			display: block;
			text-align: center;
		}
	}
	.container {
		@media (min-width: 1600px){
			width: 1600px;
		}
		width: 100%;
	}
}

.nav{
	padding: 40px 0px 30px 0px;
	@media (max-width: $mobile) {
		padding: 20px 0px 20px 0px;
	}
	position: fixed;
	width: 100%;
	top: 0;
	background-color: #fff;
	z-index: 9999;
	.container{
		position: relative;
	}
	&-items {
		display: none;
	}

	&-menu{
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translate(0%, -50%);
		&-bars{
			height: 20px;
			width: auto;
			float: left;
			cursor: pointer;
		}
	}
	&-logo{
		display: block;
		text-align: center;
		&-img{
			height: 55px;
			width: auto;
			@media (max-width: $mobile) {
				height: 40px;
			}
		}
	}
	&-phone{
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translate(0%, -50%);
		span{
			a:first-child{
				margin-right: 10px;
			}
			@media (max-width: $mobile) {
				display: none;
			}
		}

		a{
			font-size: 20px;
			color: $text;
			transition: all 0.3s;
			-webkit-backface-visibility: hidden;
		  -webkit-transform: translateZ(0) scale(1.0, 1.0);
		  transform: translateZ(0);
			&:hover, &:focus{
				opacity: 0.8;
			}
			.phone-icon{
				float: right;
				display: none;
				height: 20px;
				@media (max-width: $mobile) {
					display: block;
				}
			}
		}
		.email-icon, .phone-icon{
			float: right;
			display: none;
			height: 20px;
			width: 20px;
			margin-right: 10px;
			img{
				vertical-align: center;
			}
			@media (max-width: $mobile) {
				display: block;
			}
		}
	}
}

.nav-overlay{
	position: fixed;
	top: 0;
	left: -100%;
	height: 100%;
	z-index: 999999;
	width: 100%;
	opacity: 0;
	transition: opacity 0.3s ease;
	background-color: rgba(#1E5C61, 0.9);
	@media (max-width: $mobile) {
		overflow: scroll;
	}
	&-holder{
		width: 30%;
		height: 100%;
		@media (max-width: $mobile) {
			width: 100%;
		}
	}
	ul{
		position: absolute;
		top: 0%;
		left: 0%;
		// transform: translate(-50%, -0%);
		list-style: none;
		// text-align: center;
		padding: 0px;
		margin: $spacing*2;
		li{
			a{
				letter-spacing: 2px;
				font-weight: 600;
				display: block;
				padding: 5px;
				color: #fff;
				text-decoration: none;
				&:hover, &:focus{
					filter: brightness(85%);
				}
			}
		}
		.exit{
			padding: 0px 5px $spacing/2 0px;
			cursor: pointer;
			color: #fff;
			&:hover, &:focus{
				filter: brightness(85%);
			}
		}
	}
}
.nav-with-top {
	top: 40px;
}

.nav-top {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: $secondary;
	padding: 10px 0;
	.container {
		@media (min-width: 1600px) {
			width: 1600px;
		}
		width: 100%;
	}
	.booking-info {
		float: left;
	}
	a {
		float: right;
	}
	.booking-info, a {
		font-size: 18px;
    color: #fff;
	}
}















