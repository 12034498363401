.tribe-events-tooltip{
	display: none !important;
}

.tribe-events-button{
	background-color: #d35635 !important;
	transition: all .3s;
	&:hover{
		filter: brightness(85%);
		color: #fff !important;
	}
}

.tribe-events-nav-next, .tribe-events-nav-previous{
	a{
		color: #000;
		font-size: 18px;
		&:hover, &:focus{
			color: #000;
		}
	}
}

div[id*=tribe-events-daynum-]{
	text-align: center !important;
  color: #000 !important;
  font-size: 14px !important;
  background: #aed2cc !important;
  a{
  	color: #000 !important;
	  font-size: 14px !important;
	  background: #aed2cc !important;
  }
}

.tribe-events-calendar th{
	background-color: #d35635 !important;
	padding: 12px !important;
	color: #fff !important;
	font-size: 16px !important;
}

.tribe-events-othermonth{
	background: #aed2cc !important;
	filter: brightness(80%);
	div[id*=tribe-events-daynum-]{
		background: #aed2cc !important;
		filter: brightness(80%);
	}
}

.tribe-events-present{
	div[id*=tribe-events-daynum-]{
		background-color: #d35635 !important;
		color: #fff !important;
		a{
			color: #fff !important;
		}
	}
}

.tribe-events-month-event-title{
	font-size: 16px !important;
	padding: 5px 0px;
	a{
		color: #000;
		display: block;
		text-align: center;
	}
}

.tribe-bar-views-option-day{
	display: none !important;
}

/////// lIST VIEW
.tribe-events-event-image{
	height: 100% !important;
}

.event-holder{
	color: #000 !important;
	padding: 20px 0px;
  border-top: 1px dotted #000;
  &:first-child{
  	border: none;
  }
	a{
		color: #000 !important;
		&:hover, &:focus{
			color: inherit;
		}
	}
	h3{
		color: #d35635 !important;
		display: block !important;
		margin-bottom: 10px !important;
	}
	.tribe-events-venue-details{
		font-weight: 400 !important;
	}
	.tribe-event-date-start, .tribe-event-time{
		font-weight: 700 !important;
	}
}
.tribe-region{
	text-decoration: none !important; 
}
.type-tribe_events{
	border: none !important;
	padding: 0px !important;
}
.tribe-events-list-separator-month {
	span{
		text-transform: capitalize !important;
		font-family: Lato !important;
	}
	padding: 10px !important;
}

.popup-content{
	.tribe-event-date-start, .tribe-event-time{
		font-weight: 700 !important;
	}
	.tribe-events-venue-details{
		margin-top: 20px !important;
		.tribe-address{
			br{
				display: none;
			}
		}
	}
}

.tribe-icon-list{
	display: none !important;
	@media (max-width: $mobile) {
		display: block !important;
	}
}

.tribe-icon-month{
	display: block !important;
	@media (max-width: $mobile) {
		display: none !important;
	}
}
