.page-id-333{
	.wrapper:after{
		height: 0px;
	}
}

.gallery{
	max-height: 965px;
	overflow: hidden;
	&-text{
		position: absolute;
		bottom: 40px;
		color: #fff;
		text-align: center;
		width: 100%;
		margin: auto;
		text-shadow: 1px 1px 30px black;
		z-index: 999;
		@media (max-width: $mobile) {
			bottom: -100px;
			color: #000;
			text-shadow: none;
		}
	}
	&-holder{
		position: relative;
		@media (max-width: $mobile) {
			margin-bottom: 170px;
		}
	}
	.slick-track{
		max-height: 965px;
	}
	.arrow-prev{
		background-image: url('../images/prev-arrow.svg');
    height: 50px;
    width: 50px;
    position: absolute;
    left: 40px;
    top: 50%;
    z-index: 99;
    transform: translate(0%, -50%);
    cursor: pointer;
    @media (max-width: $mobile) {
    	height: 30px;
    	width: 30px;
    }
	}
	.arrow-next{
		background-image: url('../images/next-arrow.svg');
    height: 50px;
    width: 50px;
    position: absolute;
    right: 40px;
    top: 50%;
    z-index: 99;
    transform: translate(0%, -50%);
    cursor: pointer;
    @media (max-width: $mobile) {
    	height: 30px;
    	width: 30px;
    }
	}
	div img{
		width: 100%;
		max-height: 1700px;
	}
}

.events-slider{
	.arrow-prev{
		background-image: url('../images/prev-arrow.svg');
    height: 50px;
    width: 50px;
    position: absolute;
    left: 40px;
    top: 50%;
    z-index: 99;
    transform: translate(0%, -50%);
    cursor: pointer;
	}
	.arrow-next{
		background-image: url('../images/next-arrow.svg');
    height: 50px;
    width: 50px;
    position: absolute;
    right: 40px;
    top: 50%;
    z-index: 99;
    transform: translate(0%, -50%);
    cursor: pointer;
	}
}
