.search-results-holder{
	display: none;
	width: 100%;
	.col-12{
		padding: 0px 20px;
		margin-bottom: 20px;
		background-color: rgba(174,210,204,0.5);	
	}
	.col-12:first-child{
		padding-top: 20px;
	}
	.find-close{
		cursor: pointer;
		display: block;
		text-decoration: underline;
	}
}
.header{
	&-intro{
		width: 100%;
		height: 400px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top;
		@media (max-width: $mobile) {
			height: 200px;
		}
	}
	&-video{
		overflow: hidden;
		height: 500px;
		video{
			width: 100%;
			vertical-align: bottom;
			border: 0;
			object-fit: cover;
			@media (max-width: $mobile) {
				height: 250px;
			}
		}
	}
	&-title{
		text-align: center;
		position: relative;
		background-color: #174253;
		background-image: url('../images/flowersoverlay12.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		padding: $spacing*1.5 0px $spacing*1.5 0px;
		z-index: 1;
		@media (max-width: $mobile) {
			position: relative;
			padding: $spacing 0;
		}
		.container{
			// width: 100%;
		}
		&-text{
			color: #fff;
			font-weight: 200;
			font-size: 56px;
			text-align: center;
			overflow: hidden;
			// height: 87px;
			text-align: center;
			span{
				color: $primary;
				font-family: Quincy;
				font-weight: 600;
				font-size: 72px;
				display: inline-block;
				text-align: center;
			}
			@media (max-width: $mobile) {
				padding: 0px 20px;
				font-size: 32px;
				span{
					font-size: 52px;
				}
			}	
		}
		&-nav{
		  position: absolute;
	    top: -26px;
	    left: 50%;
	    transform: translate(-50%, 0%);
	    width: 100%;
	    @media (max-width: $mobile) {
	    	position: relative;
	    	top: 0;
	    	left: 0;
	    	transform: none;
	    	padding-top: $spacing/2;
	    }
	    .contact-btn{
	    	@media (max-width: $mobile) {
	    		display: none;
	    	}
	    }
			ul{
				padding: 0px;
				margin: 0px;
				list-style-type: none;
				@media (max-width: $mobile) {
					width: auto;
				}
				li{
					margin: 0px 5px;
					display: inline-block;
					a{
						background-color: #fff;
						font-size: 18px;
						padding: 15px 40px;
						display: block;
						transition: all 0.3s;
						color: $text;
						-webkit-backface-visibility: hidden;
					  -webkit-transform: translateZ(0) scale(1.0, 1.0);
					  transform: translateZ(0);
						&:hover{
							filter: brightness(85%);
						}
					}
					@media (max-width: 480px) {
						a{
							padding: 10px 20px;
							font-size: 14px;
						}
					}
				}
			}
		}
		.homepage-btns{
			ul li a{
				background-color: $primary;
				color: #fff;
			}
		}
		&-address{
			font-weight: 200;
			color: #fff;
			font-size: 24px;
			margin: 10px 0px 20px 0px;
		}
		&-telephone{
			font-weight: 200;
			color: #fff;
			font-size: 24px;
			margin: 10px 0px 20px 0px;
			font-weight: 700;
			a{
				color: inherit;
				&:hover, &:focus{
					filter: brightness(85%);
				}
			}
		}
		&-features{
			font-weight: 200;
			color: #fff;
			font-size: 20px;
			position: relative;
			.center{
					margin: auto;
   				display: inline-block;
				@media (max-width: $mobile) {
					text-align: center;
					span{
						width: 100%;
					}
				}
			}
			span{
				// float: left;
				font-weight: 700;
			}
			ul{
				margin: 0px;
				padding: 0px;
				list-style-type: none;
				float: left;
				li{
					display: inline-block;
					padding: 0px 5px;
					position: relative;
					font-weight: 700;
					a{
						color: inherit;
						&:hover, &:focus{
							filter: brightness(85%);
						}
					}
					&:after{
						content: '|';
						position: absolute;
						right: -5px;
					}
					&:last-of-type{
						&:after{
							display: none;
						}
					}
				}
			}
		}
	}
	&-form{
		&-title{
			color: $primary;
			font-size: 24px;
			@media (max-width: $mobile) {
				padding: 0px 0px 20px 0px;
			}
		}
		input{
			border: 0px solid #707070;
			color: $text;
			padding: 10px;
			width: 600px;
			font-size: 18px;
			height: 52px;
			@media (max-width: $mobile) {
				width: 100%;
			}
			&::placeholder {
			  color: #767676;
			  opacity: 1; /* Firefox */
			}
		}
		.btn{
			width: auto;
			border: none;
			background-color: $primary;
			color: #fff;
			padding: 15px 40px;
			cursor: pointer;
			@media (max-width: $mobile) {
				display: none;
			}
		}
		@media (max-width: $mobile) {
			padding: $spacing 0px;
		}
	}
}

.carehome-holder-near{
	// margin-top: 40px;
	position: relative;
	.carehome-holder-content{
		text-align: left;
	}
	.carehome-holder-img{
		margin-bottom: 0px;
	}
}

.primary-content{
	video{
		width: 100%;
	}
}

.find{
	text-align: center;
	padding: $spacing 0px;
	.container .col-12{
		padding: 0px 20px;
	}
	&-title{
		font-weight: 200;
		font-size: 38px;
		line-height: 52px;
		color: $secondary;
		margin-top: 0px;
		@media (max-width: $mobile) {
			font-size: 28px;
			line-height: 32px;
			color: #fff;
		}
	}
	&-form{
		&-title{
			color: $primary;
			font-size: 24px;
			font-weight: 600;
			@media (max-width: $mobile) {
				padding: 0px 0px 20px 0px;
			}
		}
		.input-search{
			position: relative;
			display: inline-block;
			@media (max-width: $mobile) {
				margin-left: 0px;
				display: block;
				&:after{
					content: '';
					position: absolute;
					right: 10px;
					top: 50%;
					transform: translate(0%, -50%);
					background-image: url('../images/search.svg');
					height: 30px;
					width: 30px;
					background-repeat: no-repeat;
				}
			}
		}
		input{
			border: 1px solid #707070;
			color: $text;
			padding: 10px;
			width: 340px;
			font-size: 18px;
			height: 52px;
			@media (max-width: $mobile) {
				width: 100%;
			}
			&::placeholder {
		    color: #767676;
		    opacity: 1; /* Firefox */
			}
		}
		.btn{
			width: auto;
			border: none;
			background-color: $primary;
			color: #fff;
			padding: 15px 40px;
			cursor: pointer;
			-webkit-appearance: none;
			@media (max-width: $mobile) {
				// display: none;
			}
		}
		@media (max-width: $mobile) {
			padding: $spacing 0px;
		}
	}
	@media (max-width: $mobile) {
		padding: 0px;
	}
}
.primary-content{
	.video-holder{
  	position: relative;
  	.video-overlay{
  		transition: all 0.3s;
  		position: absolute;
  		top: 0;
  		left: 0;
  		right: 0;
  		bottom: 0;
  		background-repeat: no-repeat;
  		background-size: cover;
  		z-index: 999;
  		cursor: pointer;
  		&:after{
  			content: '';
  			position: absolute;
  			top: 0;
    		left: 0;
    		right: 0;
    		bottom: 0;
    		background-color: rgba(#000, 0.4);
  		}
  		img{
  			position: absolute;
  			top: 50%;
  			left: 50%;
  			height: 70px;
  			transform: translate(-50%, -50%);
  			pointer-events: none;
  			z-index: 9999;
  		}
  	}
  	.video-overlay.hide{
  		opacity: 0;
  		pointer-events: none;
  	}
  	video{
    	width: 100%;
    	margin: 0px;
    }
  	@media (max-width: $mobile) {
  		margin: 0px;
  	}
  }
}

.work-with-us{
	background-color: #F9F7EF;
	.col-5{
		padding-left: 20px !important;
	}
	.video-holder{
  	position: relative;
  	.video-overlay{
  		transition: all 0.3s;
  		position: absolute;
  		top: 0;
  		left: 0;
  		right: 0;
  		bottom: 0;
  		background-repeat: no-repeat;
  		background-size: cover;
  		z-index: 999;
  		cursor: pointer;
  		&:after{
  			content: '';
  			position: absolute;
  			top: 0;
    		left: 0;
    		right: 0;
    		bottom: 0;
    		background-color: rgba(#000, 0.4);
  		}
  		img{
  			position: absolute;
  			top: 50%;
  			left: 50%;
  			height: 70px;
  			transform: translate(-50%, -50%);
  			pointer-events: none;
  			z-index: 9999;
  		}
  	}
  	.video-overlay.hide{
  		opacity: 0;
  		pointer-events: none;
  	}
  	video{
    	width: 100%;
    	margin: 0px;
    }
  	@media (max-width: $mobile) {
  		margin: 0px;
  	}
  }
	@media (max-width: 768px){
		padding-top: 40px !important;
		.col-5{
			padding-top: 40px !important;
		}
		.col-7{
			order: 2;
			padding-top: 40px !important;
			.sketch-steph:after{
				position: absolute;
				content: '';
				display: block !important;
				top: -21px;
		    left: 5px;
		    height: 150px;
		    width: 180px;
			}
		}
	}
}

.tile-large{
	background-color: #F9F9F9;
	.tile{
		padding: $spacing 0px;
		&-holder{
			&-img{
				height: 200px;
				transition: all 0.3s;
				overflow: hidden;
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			&-title{
				background-color: $primary;
				background-image: url('../images/flowersoverlay12.svg');
				background-size: 1000px;
				padding: 12px 0px;
				color: #fff;
				text-align: center;
				transition: all 0.3s;
				position: relative;
				font-size: 18px;
				font-weight: 400;
				.tile-holder-text{
					transition: all 0.3s;
					padding: 0px;
					font-size: 16px;
					opacity: 0;
					height: 0;
					overflow: hidden;
				}
			}
			&:hover, &:focus{
				.tile-holder-text{
					padding: 10px 20px 0px 20px;
					height: 50px;
					opacity: 1;
				}
				.tile-holder-img{
					filter: brightness(85%);
				}
				.tile-holder-title{
					padding: 18px 0px;
					margin-top: -62px;
				}
			}
		}
		@media (max-width: $mobile) {
			.col-4{
				padding-top: 0px; 
				&:last-of-type{
					padding-bottom: 0px;
				}
			}
		}
	}

	.content{
		padding: $spacing 0px;
		.col-7{
			padding-right: 60px;
			@media (max-width: $mobile) {
				padding: 0px 20px 40px 20px;
			}
		}
		.col-5{
			padding-left: 60px;
			position: relative;
			@media (max-width: $mobile) {
				padding: 0px 20px;
			}
		}
		.sketch-steph:after{
			content: '';
			position: absolute;
			top: -$spacing;
			left: -20px;
			height: 220px;
			width: 180px;
			background-image: url('../images/steph.svg');
			background-repeat: no-repeat;
			@media (max-width: $mobile) {
				display: none;
			}
		}
		&-main{
			&-title{
				font-weight: 400;
				color: $secondary;
				margin-top: 0px;
				span{
					color: $primary;
					font-family: Quincy;
					font-weight: 600;
					font-size: 2em;
					margin-right: 5px;
				}
			}
		}
		&-img{
			img{
				width: 100%;
			}
		}
		@media (max-width: $mobile) {
			padding-top: 0px;
		}		
	}
}

.tile{
	padding: $spacing 0px;
	&-holder{
		&-img{
			height: 200px;
			transition: all 0.3s;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&-title{
			background-color: $primary;
			padding: 12px 0px;
			color: #fff;
			text-align: center;
			transition: all 0.3s;
			position: relative;
			font-size: 14px;
			font-weight: 200;
		}
		&:hover, &:focus{
			.tile-holder-img{
				filter: brightness(85%);
			}
			.tile-holder-title{
				padding: 18px 0px;
				margin-top: -12px;
			}
		}
	}
	@media (max-width: $mobile) {
		.col-4{
			padding-top: 0px; 
			&:last-of-type{
				padding-bottom: 0px;
			}
		}
	}
}

.content{
	padding: $spacing 0px;
	.col-7{
		padding-right: 60px;
		@media (max-width: $mobile) {
			padding: 0px 20px 40px 20px;
		}
	}
	.col-5{
		padding-left: 60px;
		position: relative;
		@media (max-width: $mobile) {
			padding: 0px 20px;
		}
	}
	.sketch-steph:after{
		content: '';
		position: absolute;
		top: -$spacing;
		left: -20px;
		height: 220px;
		width: 180px;
		background-image: url('../images/steph.svg');
		background-repeat: no-repeat;
		@media (max-width: $mobile) {
			display: none;
		}
	}
	&-main{
		&-title{
			font-weight: 400;
			color: $secondary;
			margin-top: 0px;
			span{
				color: $primary;
				font-family: Quincy;
				font-weight: 600;
				font-size: 2em;
				margin-right: 5px;
			}
		}
	}
	&-img{
		img{
			width: 100%;
		}
	}
	@media (max-width: $mobile) {
		padding-top: 0px;
	}		
}

.testimonial{
	background-color: rgba($third, .35);
	background-image: url('../images/flowersoverlay.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: $spacing*2 0px $spacing*1.5 0px;
	text-align: center;
	color: $secondary;
	position: relative;
	&:after{
		content: '';
		position: absolute;
		top: 25px;
		left: 50%;
		transform: translate(-50%, 0);
		height: 50px;
		width: 50px;
		background-image: url('../images/quote.svg');
		background-repeat: no-repeat;
	}
	&-text{
		font-family: Amberly;
		font-size: 2.5em;
		@media (max-width: $mobile) {
			font-size: 2em;
		}
	}
	&-author{
		font-weight: 600;
	}
}


.welcome-statement{
	.container{
		max-width: 1000px;
	}
	margin: auto;
	.directors-quote{
		font-size: 22px;
		width: 100%;
		text-align: center;
		padding: $spacing 0px 0px 0px;
	}
	.director{
		font-size: 18px;
		font-weight: 700;
		text-align: center;
		padding: $spacingS 0px 0px 0px;
	}
}

// .homepage{
// 	.header{
// 		.header-video{
// 			@media (max-width: $mobile) {
// 				display: none;
// 			}
// 		}
// 		.header-intro{
// 			display: none;
// 			@media (max-width: $mobile) {
// 				display: block;
// 			}
// 		}
// 	}
// }

.homepage-v2{
	.primary-content .col-12{
		padding-top: 60px;
		padding-left: 100px;
		padding-right: 100px;
	}
	.header-container{
		height: 102vh;
		position: relative;
	}
	.header-title-nav {
		top: auto;
		bottom: -26px;
		width: 100%;
	}
	.header-chevron{
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0%);
		bottom: 40px;
		z-index: 999999;
		img{
			height: 50px;
		}
		@media (max-width: $mobile) {
			display: block;
		}
	}
	.header-video{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999;
		height: 100%;
		video{
			height: 100%;
		}
		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(#000, 0.4);
			@media (max-width: $mobile) {
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 52%) !important; 
				top: auto;
				height: 400px;
			}
		}
	}

.find-container.container{
	max-width: 1200px !important;
}

	.header-intro{
		background-position: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		height: 100%;
		.find-form{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 99999;
			text-align: center;
			backface-visibility: hidden;
			@media (max-width: $mobile) {
				padding-bottom: 90px;
			}
			.find-form-title{
				text-align: center;
				color: #fff;
				font-weight: 400;
				backface-visibility: hidden;
			}
			.find-form-largetitle{
				text-align: center;
				font-size: 48px;
				color: #fff;
				font-weight: 200;
				margin-bottom: 20px;
				backface-visibility: hidden;
			}
		}
		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(#000, 0.4);
			@media (max-width: $mobile) {
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 52%) !important; 
				top: auto;
				height: 400px;
			}
		}
	}
}




.homepage-v2{
	@media (max-width: $mobile) {
		.header-container{
			// height: 500px;
			height: calc(100vh - 83px);
		}
		.header-video{
			display: none;
		}
		.find-form{
			top: auto !important;
			bottom: 40px;
			padding: 0 40px;
			transform: translate(-50%,0%) !important;
			width: 100%;
			.btn{
				display: none;
			}
		}


		.primary-content{
			.col-12{
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
}
.smaller-title{
	.header-title{
		padding: 30px 0px;
	}
}
