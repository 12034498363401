// Font
$primary-font: 'Lato', sans-serif;
$secondary-font: 'Lato', sans-serif;

@font-face {
  font-family: Quincy;
  src: url('../font/QuincyCF-Regular.woff');
}
@font-face {
  font-family: Quincy;
  src: url('../font/QuincyCF-Bold.woff');
  font-weight: 700;
}
@font-face {
  font-family: Amberly;
  src: url('../font/Amberly.woff');
}

// Colours
$primary: #D35635;
$secondary: #174253;
$third: #AED2CC;
$text: #3C3C3C;


// Spacing
$col-spacing: 20px;
$spacing: 40px;

$spacingS: $spacing /2;

// Screen Sizes
$mobile: 768px;
$small-screen: 992px;
$large-screen: 1200px;

// Cookie
$cookie-background: #000;
$cookie-font-color: #fff;
$cookie-button: #2BA3DB;

.padding-top{
	.header-title{
		padding-top: 80px;
	}
}