.calander{
	@media (max-width: $mobile) {
		display: none;
	}
	max-width: 1400px;
	padding: 80px 20px;
	margin: auto;
	.month{
		border-right: 1px solid $secondary;
		border-bottom: 1px solid $secondary;
	}
	.days{
		background-color: $primary;
		text-align: center;
		ul{
			display: flex;
			li{
				color: #fff;
				flex: 1;
				padding: 10px 0px;
				&:first-of-type{
					padding: 10px 0px;
				}
			}
		}
	}

	.month-selector{
		height: 60px;
		.month-back{
			float: left;
			font-size: 22px;
			margin-top: 10px;
			margin-left: 10px;
			&:before{
				content: '< ';
			}
		}
		.month-forward{
			float: right;
			font-size: 22px;
			margin-top: 10px;
			margin-right: 10px;
			&:after{
				content: ' >';
			}
		}
		.month-title{
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0%);
			font-size: 28px;
		}
	}

	.month{
		.week{
			display: flex;
			text-align: center;
			border-top: 0px;
			.not-month{
				filter: brightness(80%);
    		background: $third;
			}
			.active{
				filter: brightness(95%);
    		background: $third;
			}
		}
		.day{
			flex: 1;
			border-left: 1px solid $secondary;
			&-date{
				background-color: $third;
				padding: 10px 0px;
			}
			&-events{
				margin: 0px 10px;
				border-bottom: 1px dashed #ddd;
				p{
					color: #666;
				}
				a{
					color: inherit;
					&:hover, &:focus{
						color: inherit;
					}
				}
				&:last-of-type{
					border: none;
				}
			}
		}
	}

}



.mobile-calander{
	display: none;
	padding: 80px 20px;
	@media (max-width: $mobile) {
		display: block;
	}
	.month-selector{
		height: 60px;
		.month-back{
			float: left;
			font-size: 22px;
			margin-top: 10px;
			margin-left: 10px;
			&:before{
				content: '< ';
			}
		}
		.month-forward{
			float: right;
			font-size: 22px;
			margin-top: 10px;
			margin-right: 10px;
			&:after{
				content: ' >';
			}
		}
		.month-title{
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0%);
			font-size: 28px;
		}
	}
	.mobile-day{
		padding: 20px 0px;
		// border-bottom: 2px solid $secondary;
		.mobile-day-date{
			font-size: 26px;
			margin-bottom: 20px;
		}
	}
	.mobile-calander-event{
		padding: 20px;
		border-bottom: 1px solid $secondary;
		&:last-of-type{
			border: none;
		}
		.mobile-calander-event-title{
			font-size: 22px;
			margin-bottom: 10px;
		}
		p{
			margin-top: 0px;
		}
	}
}
