.form-group {
	width: 100%;
	input, textarea {
		display: block;
		width: 100%;
		font-size: inherit;
		font-family: inherit;
		padding: 12px;
		border: 1px solid #707070;
		margin: 0px 0px $spacing 0px;
		-webkit-appearance: none;
		&::placeholder {
	    color: #767676;
	    opacity: 1; /* Firefox */
		}
	}
	textarea{
		resize: none;
		height: 160px;
	}
	button{
		margin-top: 0px;
		border: none;
		cursor: pointer;
	}
}



.parsley-error{
	background-color: #F2DEDE !important;
	margin: 0px 0px 5px 0px !important;
}
.parsley-errors-list{
	list-style: none;
	padding: 0px !important;
	color: #A9555B;
	margin: 0px 0px 16px 0px !important;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #757575; 
}
::-moz-placeholder { /* Firefox 19+ */
  color: #757575; 
  opacity: 1;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #757575; 
  opacity: 1;
}
:-moz-placeholder { /* Firefox 18- */
  color: #757575; 
  opacity: 1;
}
select{
	color: #757575; 
}

.alert{
	margin: 10px 0px;
	padding: 10px;
	&-success{
		background-color: #DFF0D8;
		color: #5B763D;
	}
	&-error{
		background-color: #F2DEDE;
		color: #A9555B;
	}
}

#carehome-arrange-field{
	display: none;
}


.carehome-form{
	.preferred{
		select{
			color: #999999;
			font-weight: 200;
		}
	}
}

